/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useTheme } from "@emotion/react";

const NotFoundIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const isDarkMode = useTheme().mode === "dark";

  if (isDarkMode) {
    return <svg xmlns='http://www.w3.org/2000/svg' width='96' height='95' fill='none' viewBox='0 0 96 95' {...props}>
                <path fill='#fff' fillOpacity='0.15' stroke='#fff' strokeOpacity='0.7' strokeWidth='1.563' d='M42.563 63.625c11.563 0 20.937-9.374 20.937-20.938 0-11.563-9.374-20.937-20.938-20.937-11.563 0-20.937 9.374-20.937 20.938 0 11.563 9.374 20.937 20.938 20.937z' />
                <path stroke='#fff' strokeLinecap='round' strokeOpacity='0.7' strokeWidth='1.563' d='M39.208 58.934c1.099.203 2.217.308 3.355.316 9.147 0 16.562-7.415 16.562-16.563 0-9.147-7.415-16.562-16.563-16.562-2.35 0-4.585.49-6.61 1.372a16.628 16.628 0 00-8.142 7.654A16.492 16.492 0 0026 42.687a16.502 16.502 0 004.178 10.998M32.123 55.546a16.547 16.547 0 004.502 2.608' />
                <path stroke='#fff' strokeOpacity='0.7' strokeWidth='1.563' d='M59.75 59.25L63.5 63' />
                <path fill='#fff' fillOpacity='0.7' fillRule='evenodd' stroke='#fff' strokeOpacity='0.7' strokeWidth='1.563' d='M62.893 62.394a3.03 3.03 0 000 4.284l6.928 6.928a3.03 3.03 0 004.284-4.284l-6.928-6.928a3.03 3.03 0 00-4.284 0z' clipRule='evenodd' />
                <path stroke='#161424' strokeLinecap='round' strokeWidth='1.563' d='M66 63.625l6.875 6.875' />
                <path fill='#fff' fillOpacity='0.15' fillRule='evenodd' d='M38.5 35.5c0 7.249 5.876 13.125 13.125 13.125 1.424 0 2.795-.227 4.079-.646-2.096 5.2-7.19 8.871-13.142 8.871-7.822 0-14.163-6.34-14.163-14.163 0-7.087 5.207-12.96 12.004-13.999A13.07 13.07 0 0038.5 35.5z' clipRule='evenodd' />
                <path stroke='#fff' strokeLinecap='round' strokeOpacity='0.7' strokeWidth='1.563' d='M42.875 31.125c-.796 0-1.574.078-2.325.228m-2.272.694C34.002 33.844 31 38.072 31 43' />
                <path stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeOpacity='0.7' strokeWidth='1.563' d='M75.907 35.36h-5.11m9.063-4.86H69.125 79.86zm4.375 0h-1.388 1.388zM32.186 72.86h-5.11m2.187-5.485H18.528h10.735zm-14.375 0H12.25h2.638z' />
            </svg>;
  }

  return <svg xmlns='http://www.w3.org/2000/svg' width='96' height='95' fill='none' viewBox='0 0 96 95' {...props}>
            <path fill='#EEF2F6' stroke='#9AA4B2' strokeWidth='1.563' d='M42.563 63.625c11.563 0 20.937-9.374 20.937-20.938 0-11.563-9.374-20.937-20.938-20.937-11.563 0-20.937 9.374-20.937 20.938 0 11.563 9.374 20.937 20.938 20.937z' />
            <path fill='#fff' fillRule='evenodd' d='M39.208 58.934c1.099.203 2.217.308 3.355.316 9.147 0 16.562-7.415 16.562-16.563 0-9.147-7.415-16.562-16.563-16.562-2.35 0-4.585.49-6.61 1.372a16.628 16.628 0 00-8.142 7.654A16.492 16.492 0 0026 42.687a16.502 16.502 0 004.178 10.998' clipRule='evenodd' />
            <path stroke='#9AA4B2' strokeLinecap='round' strokeWidth='1.563' d='M39.208 58.934c1.099.203 2.217.308 3.355.316 9.147 0 16.562-7.415 16.562-16.563 0-9.147-7.415-16.562-16.563-16.562-2.35 0-4.585.49-6.61 1.372a16.628 16.628 0 00-8.142 7.654A16.492 16.492 0 0026 42.687a16.502 16.502 0 004.178 10.998M32.123 55.547a16.547 16.547 0 004.502 2.607' />
            <path stroke='#9AA4B2' strokeWidth='1.563' d='M59.75 59.25L63.5 63' />
            <path fill='#9AA4B2' fillRule='evenodd' stroke='#9AA4B2' strokeWidth='1.563' d='M62.894 62.393a3.03 3.03 0 000 4.284l6.928 6.928a3.029 3.029 0 104.284-4.284l-6.928-6.928a3.03 3.03 0 00-4.284 0z' clipRule='evenodd' />
            <path stroke='#fff' strokeLinecap='round' strokeWidth='1.563' d='M66 63.625l6.875 6.875' />
            <path fill='#EEF2F6' fillRule='evenodd' d='M38.5 35.5c0 7.248 5.876 13.124 13.125 13.124 1.424 0 2.795-.226 4.079-.646-2.096 5.2-7.19 8.872-13.142 8.872-7.822 0-14.163-6.341-14.163-14.163 0-7.088 5.207-12.96 12.004-14A13.07 13.07 0 0038.5 35.5z' clipRule='evenodd' />
            <path stroke='#CDD5DF' strokeLinecap='round' strokeWidth='1.563' d='M42.875 31.125c-.796 0-1.574.078-2.325.228m-2.272.694C34.002 33.844 31 38.072 31 43' />
            <path stroke='#CDD5DF' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.563' d='M75.907 35.36h-5.11m9.063-4.86H69.125 79.86zm4.375 0h-1.388 1.388zM32.186 72.86h-5.11m2.187-5.485H18.528h10.735zm-14.375 0H12.25h2.638z' />
        </svg>;
};

export default NotFoundIcon;