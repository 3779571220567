import _styled2 from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import React from "react";
import { useTranslation } from "next-i18next";
import NotFoundIcon from "@assets/svgx/NotFoundIcon";

const Wrapper = _styled2("div", process.env.NODE_ENV === "production" ? {
  target: "ecyt05z2"
} : {
  target: "ecyt05z2",
  label: "Wrapper"
})(process.env.NODE_ENV === "production" ? {
  name: "1kht0wz",
  styles: "display:flex;align-items:center;justify-content:center;flex-direction:column;padding:50px 20px"
} : {
  name: "1kht0wz",
  styles: "display:flex;align-items:center;justify-content:center;flex-direction:column;padding:50px 20px",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvRW1wdHkvRW1wdHkudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQU0wQiIsImZpbGUiOiIvdmVyY2VsL3BhdGgwL3NyYy9jb21wb25lbnRzL0VtcHR5L0VtcHR5LnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tIFwicmVhY3RcIlxuaW1wb3J0IHsgdXNlVHJhbnNsYXRpb24gfSBmcm9tIFwibmV4dC1pMThuZXh0XCJcbmltcG9ydCB7IHN0eWxlZCB9IGZyb20gXCJ0d2luLm1hY3JvXCJcblxuaW1wb3J0IE5vdEZvdW5kSWNvbiBmcm9tIFwiQGFzc2V0cy9zdmd4L05vdEZvdW5kSWNvblwiXG5cbmNvbnN0IFdyYXBwZXIgPSBzdHlsZWQuZGl2YFxuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIHBhZGRpbmc6IDUwcHggMjBweDtcbmBcblxuY29uc3QgVGV4dCA9IHN0eWxlZC5zcGFuYFxuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbmBcbmNvbnN0IFN1YnRpdGxlID0gc3R5bGVkLnNwYW5gXG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuYFxuXG5pbnRlcmZhY2UgRW1wdHlQcm9wcyB7XG4gICAgY2xhc3NOYW1lPzogc3RyaW5nXG4gICAgaW1hZ2U/OiBzdHJpbmcgfCBSZWFjdC5SZWFjdE5vZGVcbiAgICB0ZXh0PzogUmVhY3QuUmVhY3ROb2RlXG4gICAgc3VidGl0bGU/OiBzdHJpbmcgfCBSZWFjdC5SZWFjdE5vZGVcbn1cblxuY29uc3QgRW1wdHk6IFJlYWN0LkZDPEVtcHR5UHJvcHM+ID0gKHsgY2xhc3NOYW1lLCBpbWFnZSwgdGV4dCwgc3VidGl0bGUgfSkgPT4ge1xuICAgIGNvbnN0IHsgdCB9ID0gdXNlVHJhbnNsYXRpb24oXCJjb21tb25cIilcblxuICAgIHJldHVybiAoXG4gICAgICAgIDxXcmFwcGVyIGNsYXNzTmFtZT17Y2xhc3NOYW1lfT5cbiAgICAgICAgICAgIHtpbWFnZSB8fCA8Tm90Rm91bmRJY29uIHdpZHRoPXsxNDB9IGhlaWdodD17MTQwfSAvPn1cbiAgICAgICAgICAgIDxUZXh0Pnt0ZXh0IHx8IHQoXCJjb21tb246bWVzc2FnZS5ub19kYXRhXCIpfS48L1RleHQ+XG4gICAgICAgICAgICB7c3VidGl0bGUgJiYgPFN1YnRpdGxlPntzdWJ0aXRsZX08L1N1YnRpdGxlPn1cbiAgICAgICAgPC9XcmFwcGVyPlxuICAgIClcbn1cblxuRW1wdHkuZGVmYXVsdFByb3BzID0ge1xuICAgIGNsYXNzTmFtZTogdW5kZWZpbmVkLFxuICAgIGltYWdlOiB1bmRlZmluZWQsXG4gICAgdGV4dDogdW5kZWZpbmVkLFxuICAgIHN1YnRpdGxlOiB1bmRlZmluZWRcbn1cblxuZXhwb3J0IGRlZmF1bHQgRW1wdHlcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

const Text = _styled2("span", process.env.NODE_ENV === "production" ? {
  target: "ecyt05z1"
} : {
  target: "ecyt05z1",
  label: "Text"
})(process.env.NODE_ENV === "production" ? {
  name: "1azakc",
  styles: "text-align:center"
} : {
  name: "1azakc",
  styles: "text-align:center",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvRW1wdHkvRW1wdHkudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQWN3QiIsImZpbGUiOiIvdmVyY2VsL3BhdGgwL3NyYy9jb21wb25lbnRzL0VtcHR5L0VtcHR5LnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tIFwicmVhY3RcIlxuaW1wb3J0IHsgdXNlVHJhbnNsYXRpb24gfSBmcm9tIFwibmV4dC1pMThuZXh0XCJcbmltcG9ydCB7IHN0eWxlZCB9IGZyb20gXCJ0d2luLm1hY3JvXCJcblxuaW1wb3J0IE5vdEZvdW5kSWNvbiBmcm9tIFwiQGFzc2V0cy9zdmd4L05vdEZvdW5kSWNvblwiXG5cbmNvbnN0IFdyYXBwZXIgPSBzdHlsZWQuZGl2YFxuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIHBhZGRpbmc6IDUwcHggMjBweDtcbmBcblxuY29uc3QgVGV4dCA9IHN0eWxlZC5zcGFuYFxuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbmBcbmNvbnN0IFN1YnRpdGxlID0gc3R5bGVkLnNwYW5gXG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuYFxuXG5pbnRlcmZhY2UgRW1wdHlQcm9wcyB7XG4gICAgY2xhc3NOYW1lPzogc3RyaW5nXG4gICAgaW1hZ2U/OiBzdHJpbmcgfCBSZWFjdC5SZWFjdE5vZGVcbiAgICB0ZXh0PzogUmVhY3QuUmVhY3ROb2RlXG4gICAgc3VidGl0bGU/OiBzdHJpbmcgfCBSZWFjdC5SZWFjdE5vZGVcbn1cblxuY29uc3QgRW1wdHk6IFJlYWN0LkZDPEVtcHR5UHJvcHM+ID0gKHsgY2xhc3NOYW1lLCBpbWFnZSwgdGV4dCwgc3VidGl0bGUgfSkgPT4ge1xuICAgIGNvbnN0IHsgdCB9ID0gdXNlVHJhbnNsYXRpb24oXCJjb21tb25cIilcblxuICAgIHJldHVybiAoXG4gICAgICAgIDxXcmFwcGVyIGNsYXNzTmFtZT17Y2xhc3NOYW1lfT5cbiAgICAgICAgICAgIHtpbWFnZSB8fCA8Tm90Rm91bmRJY29uIHdpZHRoPXsxNDB9IGhlaWdodD17MTQwfSAvPn1cbiAgICAgICAgICAgIDxUZXh0Pnt0ZXh0IHx8IHQoXCJjb21tb246bWVzc2FnZS5ub19kYXRhXCIpfS48L1RleHQ+XG4gICAgICAgICAgICB7c3VidGl0bGUgJiYgPFN1YnRpdGxlPntzdWJ0aXRsZX08L1N1YnRpdGxlPn1cbiAgICAgICAgPC9XcmFwcGVyPlxuICAgIClcbn1cblxuRW1wdHkuZGVmYXVsdFByb3BzID0ge1xuICAgIGNsYXNzTmFtZTogdW5kZWZpbmVkLFxuICAgIGltYWdlOiB1bmRlZmluZWQsXG4gICAgdGV4dDogdW5kZWZpbmVkLFxuICAgIHN1YnRpdGxlOiB1bmRlZmluZWRcbn1cblxuZXhwb3J0IGRlZmF1bHQgRW1wdHlcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

const Subtitle = _styled2("span", process.env.NODE_ENV === "production" ? {
  target: "ecyt05z0"
} : {
  target: "ecyt05z0",
  label: "Subtitle"
})(process.env.NODE_ENV === "production" ? {
  name: "1azakc",
  styles: "text-align:center"
} : {
  name: "1azakc",
  styles: "text-align:center",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvRW1wdHkvRW1wdHkudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQWlCNEIiLCJmaWxlIjoiL3ZlcmNlbC9wYXRoMC9zcmMvY29tcG9uZW50cy9FbXB0eS9FbXB0eS50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgUmVhY3QgZnJvbSBcInJlYWN0XCJcbmltcG9ydCB7IHVzZVRyYW5zbGF0aW9uIH0gZnJvbSBcIm5leHQtaTE4bmV4dFwiXG5pbXBvcnQgeyBzdHlsZWQgfSBmcm9tIFwidHdpbi5tYWNyb1wiXG5cbmltcG9ydCBOb3RGb3VuZEljb24gZnJvbSBcIkBhc3NldHMvc3ZneC9Ob3RGb3VuZEljb25cIlxuXG5jb25zdCBXcmFwcGVyID0gc3R5bGVkLmRpdmBcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBwYWRkaW5nOiA1MHB4IDIwcHg7XG5gXG5cbmNvbnN0IFRleHQgPSBzdHlsZWQuc3BhbmBcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG5gXG5jb25zdCBTdWJ0aXRsZSA9IHN0eWxlZC5zcGFuYFxuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbmBcblxuaW50ZXJmYWNlIEVtcHR5UHJvcHMge1xuICAgIGNsYXNzTmFtZT86IHN0cmluZ1xuICAgIGltYWdlPzogc3RyaW5nIHwgUmVhY3QuUmVhY3ROb2RlXG4gICAgdGV4dD86IFJlYWN0LlJlYWN0Tm9kZVxuICAgIHN1YnRpdGxlPzogc3RyaW5nIHwgUmVhY3QuUmVhY3ROb2RlXG59XG5cbmNvbnN0IEVtcHR5OiBSZWFjdC5GQzxFbXB0eVByb3BzPiA9ICh7IGNsYXNzTmFtZSwgaW1hZ2UsIHRleHQsIHN1YnRpdGxlIH0pID0+IHtcbiAgICBjb25zdCB7IHQgfSA9IHVzZVRyYW5zbGF0aW9uKFwiY29tbW9uXCIpXG5cbiAgICByZXR1cm4gKFxuICAgICAgICA8V3JhcHBlciBjbGFzc05hbWU9e2NsYXNzTmFtZX0+XG4gICAgICAgICAgICB7aW1hZ2UgfHwgPE5vdEZvdW5kSWNvbiB3aWR0aD17MTQwfSBoZWlnaHQ9ezE0MH0gLz59XG4gICAgICAgICAgICA8VGV4dD57dGV4dCB8fCB0KFwiY29tbW9uOm1lc3NhZ2Uubm9fZGF0YVwiKX0uPC9UZXh0PlxuICAgICAgICAgICAge3N1YnRpdGxlICYmIDxTdWJ0aXRsZT57c3VidGl0bGV9PC9TdWJ0aXRsZT59XG4gICAgICAgIDwvV3JhcHBlcj5cbiAgICApXG59XG5cbkVtcHR5LmRlZmF1bHRQcm9wcyA9IHtcbiAgICBjbGFzc05hbWU6IHVuZGVmaW5lZCxcbiAgICBpbWFnZTogdW5kZWZpbmVkLFxuICAgIHRleHQ6IHVuZGVmaW5lZCxcbiAgICBzdWJ0aXRsZTogdW5kZWZpbmVkXG59XG5cbmV4cG9ydCBkZWZhdWx0IEVtcHR5XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

interface EmptyProps {
  className?: string;
  image?: string | React.ReactNode;
  text?: React.ReactNode;
  subtitle?: string | React.ReactNode;
}

const Empty: React.FC<EmptyProps> = ({
  className,
  image,
  text,
  subtitle
}) => {
  const {
    t
  } = useTranslation("common");
  return <Wrapper className={className}>
            {image || <NotFoundIcon width={140} height={140} />}
            <Text>{text || t("common:message.no_data")}.</Text>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Wrapper>;
};

Empty.defaultProps = {
  className: undefined,
  image: undefined,
  text: undefined,
  subtitle: undefined
};
export default Empty;